.WheelMain {
    position: relative;
    width: 500px;
    height: 500px;
    z-index: 10;
}

@keyframes pulsate {
    0% {
        box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
        /* Green highlight with 0.5 opacity */
    }

    50% {
        box-shadow: 0 0 20px rgba(0, 255, 0, 1),
            0 0 30px rgba(0, 255, 0, 1);
        /* Increase the size and intensity of the highlight */
    }

    100% {
        box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
        /* Return to the initial highlight */
    }
}

.pulsating-effect {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    background-color: #333;
    border-radius: 5px;
    animation: pulsate 1.5s infinite;
    /* Применяем анимацию */
}

.WheelMain::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 30px solid #f00;
    z-index: 9999999;
}


.wheel {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    border: 10px solid #fff;
    overflow: hidden;
    transition: all ease 4s;
    transform-origin: 50% 50%;
}

.span {
    width: 50%;
    height: 50%;
    display: inline-block;
    position: absolute;
}

.span1 {
    clip-path: polygon(50% 100%, 92% 0, 8% 0);
    background-color: #ff1f1f;
    top: 0;
    left: 120px;
}


.span2 {
    clip-path: polygon(60% 0, 100% 100%, 0 60%);
    background-color: #19e3cf;
    top: -2px;
    right: 242px;
}

.span7 {
    clip-path: polygon(100% 92%, 0 50%, 100% 8%);
    background-color: #9e0bf3;
    top: 120px;
    right: 0;
}

.span5 {
    clip-path: polygon(50% 0%, 8% 100%, 92% 100%);
    background-color: #15b600;
    bottom: 0;
    left: 120px;
}

.span4 {
    clip-path: polygon(0 40%, 100% 0%, 60% 100%);
    background-color: #1f26ff;
    bottom: -2px;
    right: 242px;
}

.span6 {
    clip-path: polygon(40% 100%, 0 0%, 100% 40%);
    background-color: #ff5a5a;
    bottom: -2px;
    left: 242px;
}

.span3 {
    clip-path: polygon(0 92%, 100% 50%, 0 8%);
    top: 120px;
    left: 0;
    background-color: #57fff1;

}

.span8 {
    clip-path: polygon(0 100%, 100% 60%, 40% 0);
    background-color: #ff9612;
    top: -2px;
    left: 242px;
}

span p {
    width: 65px;
    height: 65px;
    font-size: 60px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spin {
    position: absolute;
    top: 43%;
    left: 43%;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: black;
    color: #fff;
    box-shadow: 0 5px 20px #000;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.09, 1.09, 1.09);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.spin:active {
    width: 70px;
    height: 70px;
    font-size: 20px;
    background: #ffe605;
}


@keyframes shake {
    0% {
        transform: translate(2px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(0px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(2px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(2px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.snake1 {
    color: "red"
}

.shake:hover {
    animation-name: shake;
    animation-duration: 0.8s;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./components/endpoints.css";


#root {
    background-color: #154234;
    height: 100%;
}
body{
    height: 100%;
}


.filepond--credits {
    display: none;
}






.frame {
    width: 400px;
    height: 400px;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

#text {
    position: relative;
}

#text h1 {
    cursor: pointer;
    font-family: "Space Grotesk", sans-serif;
    font-size: 34px;
    line-height: 20%;
}

#text::before {
    content: "";
    inset: 0;
    position: absolute;
    z-index: -1;
    border-bottom: 5px solid #000000;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 300ms ease;
}

#text:hover::before {
    transform: scaleX(100%);
    transform-origin: left;
}

.Link {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.Link:hover {
    color: white;
}

.Link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    /* Цвет подчеркивания */
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
    /* Длительность и стиль анимации */
}

.Link:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

.MuiDialog-paper {
    background-color: #BCEBDC!important;
    border-radius: 20px!important;
}

@import "./wheel/wheel.css";

.AuthTitle {
    color: #FFF;
    text-align: center;
    /* font-family: Raleway; */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    /* 67.2px */
}

.AuthInput {    
    border-radius: 30px!important;
    background-color: #F8FAFC!important;
}



.ProfileTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
}

.PolicyTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
}

.PurchaseTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
}

.PurchaseSubtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
}

.WheelTitle1{
    font-family: Raleway;
    font-size: 48px;
    font-weight: 700;
    line-height: 67px;
    letter-spacing: 0em;
    text-align: center;
}

